import Vue from 'vue'
import Vuex from 'vuex'
import { setProduct } from './../models/Products'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    snackbar: null,    
    loadingStateText: "",
    productsList:[],
    productsLoaded: false
  },
  getters: {
    loading(state) {
      return state.loading
    },    
    loadingStateText(state) {
      return state.loadingStateText
    },
    snackbar(state) {
      return state.snackbar
    },
    products(){
      return [setProductTemplate('Flower Lehenga'), setProductTemplate('Ayan Lehenga'), setProductTemplate('Ujjal Lehenga')]
    },
    productsList(state){
      return state.productsList
    },
    productsLoaded(state){
      return state.productsLoaded
    }
  },
  mutations: {
    SET_LOADING_STATE(state, data) {
      state.loading = data
    },
    SET_LOADING_TEXT(state, data) {
      state.loadingStateText = data
    },
    SET_SNACKBAR(state, data) {
      state.snackbar = data
    },  
    SET_PRODUCTS_LIST(state, data){
      state.productsList = data
    },
    SET_PRODUCTS_LOADED(state, data){
      state.productsLoaded = data
    }
  },
  actions: {
    addProduct({ }, productInfo) {            
      return setProduct(productInfo, {})
        .then(() => {                    
          return Promise.resolve(true)
        })
        .catch(e => Promise.reject(e))
    },
    showLoading({ commit }, data) {
      commit("SET_LOADING_STATE", true)
      // commit("SET_LOADING_TEXT", data)
    },
  }
  // modules: {
  // }
})




function setProductTemplate(name) {
  return {
    id: Math.random().toString(36).substring(2,7),
    name: name,        
    collection: ['New Arrivals'],
    collection_slugs:['new-arrivals'],        
    category: ['Lehanga Sets'],
    category_slugs : ['lehenga-sets'],
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],        
    defaultSize: "S",
    defaultQty: 1,  
    startingPrice: 20000, 
    images: ['https://storage.googleapis.com/harshita-jain.appspot.com/tangerine%20flower%20lehanga%2014%2C800/compress/DSC_3251%20L.jpg','',''],     
    description: "lorem description",
    isInStock: true,
    price: Array.from({ length: 7 }, (_, i) => 20000),
    details: [
      {
        title: "Fabric",
        value: "",
      },
      {
        title: "Shipping Time",
        value: "Weeks",
      },
      {
        title: "Wash Care",
        value: ""
      },
      {
        title: "Availablity",
        value: ""
      }
    ],
    slug:name.split(' ').join('-').toLowerCase(),
    tags: ['isFeatured']          
  }
}
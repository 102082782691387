import { productsRef } from "../services/Database";

export function setProduct(productInfo, meta) {
  if(!productInfo) { return }
    const docId = productsRef.doc().id    
    return productsRef
      .doc(docId)
      .set({
        ...productInfo,
        id: docId,
      })
      .then(doc => {
        return Promise.resolve(true)
      })     
  }

export function listProducts() {
  let products = []
  return productsRef
    .orderBy("pos", "asc")
    .get()
    .then(snaps => {
      snaps.forEach(snap => products.push(snap.data()))
      return Promise.resolve(products)
    })
    .catch(err => Promise.reject(err))
}

export function getProduct(productId) {
  return productsRef
    .doc(productId)
    .get()
    .then(doc => doc.data())
    .catch(err => err)
}

// function mockProduct(id, name, category, collection, tags) {
//   return {
//     id,
//     name,
//     category,
//     collection,
//     vendor: "Rebase Designs",
//     tags,
//     sizes: ["S","M","L"],
//     colors: [
//       {
//         name: "Black",
//         code: "#000"
//       },
//       {
//         name: "White",
//         code: "#fff"
//       },
//       {
//         name: "Navy",
//         code: "blue"
//       }
//     ],
//     maxQty: 4,
//     defaultSize: "M",
//     defaultQty: 1,
//     defaultColor: 'Black',
//     description: "This is a cool product",
//     isInStock: true,
//     price: 400,
//     details: [
//       {
//         title: "Country of origin",
//         value: "India",
//       },
//       {
//         title: "Material",
//         value: "100% Cotton",
//       },
//     ],
//   };
// }
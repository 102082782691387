<template>
<div>
  <div class="text-xs-center">
    <v-dialog
      :value="true"
      persistent
      width="300"
    >
      <v-card class="py-5">
        <v-card-text class="pb-0">
            <v-row align="center">
              <v-col cols="3" class="pl-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>  
              </v-col>
              <v-col cols="9">
                {{ loadingText }}
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>  
</div>
</template>

<script>
export default {
  props: {
    loadingText: {
      required: false,
      default: 'Loading...'
    }
  },
  destroyed() {
    this.$store.commit("SET_LOADING_TEXT", 'Loading...')
  }
}
</script>
import { carouselRef } from "../services/Database"

export function setCarousel(id, url, isDraft) {
  console.log('URL', url)

  return carouselRef.doc(id)
    .set(url)

  // if(isDraft){
  //   if(url.hasOwnProperty('draft')){
  //     console.log('Update existing draft')
  //   } else {
  //     console.log('Set a draft property')
  //   }
  // }


  // if(isDraft){
  //   console.log('DRAFT')
  //   return carouselRef.doc(id)
  //   .update({ draft: url })
  // }
  // else{
  //   console.log('NOT draft', url)
  // }
  // return
  // return carouselRef.doc(id)
  //   .set(url)
}

export function getCarousel() {
  let res = []
  return carouselRef.get()
    .then(snaps => {
      snaps.forEach(snap => res.push(snap.data()))
      return res
    })
}


export function deleteCarousel(id){
  return carouselRef.doc(id).delete()
}
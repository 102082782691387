import firebase from "./firebaseInit"

export const auth = firebase.auth;
export const currentUser = auth().currentUser
export const authData = auth().currentUser
const provider = new auth.GoogleAuthProvider()

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    auth().signInWithEmailAndPassword(credentials.email, credentials.password)
    .then(result => {
      const user = result.user;
      const isFirstLogin = result.additionalUserInfo.isNewUser
      resolve({...user,isFirstLogin})      
  }).catch(error => {
      reject(error)
  });
  })  
}

export function signInWithGoogle() {
  return new Promise((resolve, reject) => {
    auth()
    .signInWithPopup(provider)
    .then(result => {
      const user = result.user;
      const isFirstLogin = result.additionalUserInfo.isNewUser
      resolve({...user,isFirstLogin})      
  }).catch(error => {
      reject(error)
  });
  })
}

export function signOut() {  
  return auth().signOut()
}

export const authActions = { signIn, signInWithGoogle, signOut }
import store from "./../store"

export function triggerAction(action, payload, loading=true) {
    return new Promise((resolve, reject) => {
        if(loading) { store.commit("SET_LOADING_STATE", true) }
        store
        .dispatch(action, payload)
        .then(() => {
            setTimeout(() => store.commit("SET_LOADING_STATE", false), 100)
            resolve()
        })
        .catch(err => {
            setTimeout(() => store.commit("SET_LOADING_STATE", false), 100)
            store.commit("SET_SNACKBAR", { message: !!err? err : "There seems to be an error" })          
            reject(err)
        })
    })
}

export function triggerActionExt(action, payload) {
    return new Promise((resolve, reject) => {
        if(!payload.hideLoader)
            store.commit("SET_LOADING_STATE", true)        
        store.dispatch(action, payload.data)
        .then(() => {
            if (payload.resolveMsg) { 
                store.commit("SET_SNACKBAR", { message: payload.resolveMsg } )
            }
            resolve()
        })
        .catch(err => {
            store.commit("SET_SNACKBAR", { message: "There seems to be an error" })
            reject(err)
        })
        .finally(() => {
            if(!payload.hideLoader)
            setTimeout(() => store.commit("SET_LOADING_STATE", false), 100)        
        })
    })
}


export const triggerBatchActions = (batch) => {
    return new Promise((resolve) => {
        store.commit("SET_LOADING_STATE", true)
        batch.forEach(async b => {
            await triggerAction(b.action, b.payload, false)
        })
        store.commit("SET_LOADING_STATE", false)
        resolve()
    })

}

export function ProgressLoader(handler) {
    return new Promise(() => {
        return handler()
    })
}

export function calcDiscount(sum, discount) {
    let discountRate = !!discount.rate? discount.rate : 0
    let discountVal = Math.ceil(sum * discountRate * 0.01)
    return (discountVal !== 0 && discountVal > discount.maxDiscount)? discount.maxDiscount : discountVal         
}
<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="3">
          <v-radio-group
            v-model="row"
            row
          >
            <v-radio
              label="Create New"
              value="radio-1"
              @click="setProductTemplate"
            ></v-radio>
            <v-radio
              label="Update Existing"
              value="radio-2"
            ></v-radio>
          </v-radio-group>          

          <v-select             
            v-if="row == 'radio-2'"
            :items="products"
            item-text="name"
            item-value="id"
            label="Select Product"
            return-object
            single-line
            @change="onProductChange($event)"       
            >            
          </v-select>
        </v-col>
        <v-col md="9">
          <v-card>
            <v-card-text>
              <v-text-field label="Name" v-model="product.name"></v-text-field>
              <v-text-field label="Price" v-model.number="product.price"></v-text-field>
              <!-- <v-select label="Category" v-model="product.category[0]" :items="['Lehenga Sets', 'Kurta Sets']"></v-select> -->
              <v-text-field label="Image 1" v-model="product.images[0]"></v-text-field>    
              <v-text-field label="Image 2" v-model="product.images[1]"></v-text-field>    
              <v-text-field label="Image 3" v-model="product.images[2]"></v-text-field>    
              <v-text-field label="Description" v-model="product.description"></v-text-field>    
              <!-- <v-text-field label="Fabric" v-model="product.details[0].value"></v-text-field>     -->
              <v-text-field label="Delivery Time" v-model="product.details[0].value"></v-text-field>    
              <!-- <v-text-field label="Wash Care" v-model="product.details[2].value"></v-text-field>     -->
              <!-- <v-select label="Availability" v-model="product.details[3].value" :items="['Made To Order', 'Ready To Ship']"></v-select> -->
              <v-btn class="mt-2" depressed @click="addProduct">SUBMIT</v-btn>        
            </v-card-text>
          </v-card>          
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { triggerAction } from './../services/common'
import { productsRef, jewelleryProductsRef } from './../services/Database'

  export default {
    data: () =>({
      product: { },
      products: [],
      row: 'radio-1'
    }),
    created() {    
      this.setProductTemplate()

      jewelleryProductsRef.onSnapshot(snaps => {
        this.products = []
        snaps.forEach(snap => {
          this.products.push(snap.data())
        })
      })

    },
    methods: {
      addProduct() {                
        this.runProductAction()
        .then(() => {
          this.$store.commit("SET_SNACKBAR", { message: "Done" } )          
        })
        .catch(e => {
          console.log(e)
          this.$store.commit("SET_SNACKBAR", { message: "Error" } )
        })                
      },
      runProductAction() {
        if(this.row == 'radio-2') {
          return productsRef.doc(this.product.id).set(this.product)
        } else {
          let _product = {
            ...this.product,
            type: 'jewellery',
            category_slugs: [],
            collection_slugs: [],
            slug: this.product.name.split(' ').join('-').toLowerCase()
          }
          return triggerAction("addProduct", _product)          
        }
      },
      onProductChange(value) {
        this.product = JSON.parse(JSON.stringify(value))
      },
      setProductTemplate() {
        this.product = {
          name: '',         
          defaultQty: 1,
          category: [],
          collection: [],
          images: ['','',''],     
          description: "",
          isInStock: true,
          price: null,
          details: [
            {
              title: "Shipping Time",
              value: "Weeks",
            }
          ],
          tags: []          
        }
      }
    },
    components: {      
    },
  }
</script>

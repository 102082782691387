<template>
  <div>
    <v-btn @click="test_01">GET PRODUCTS</v-btn>
    {{ products }}

    <button @click="syncSearch">Sync with search</button>
    <!-- <div>
      <p>Upload an image to Firebase:</p>
      <input type="file" @change="previewImage" accept="image/*" />
    </div>
    <div>
      <p>
        Progress: {{ uploadValue.toFixed() + "%" }}
        <progress id="progress" :value="uploadValue" max="100"></progress>
      </p>
    </div>
    <div v-if="imageData != null">
      <img class="preview" :src="picture" />
      <br />
      <button @click="onUpload">Upload</button>
    </div> -->
  </div>
</template>


<script>
// import firebase from "firebase";
import { listProducts } from './../api/product'
import { mapGetters } from 'vuex'

export default {
  name: "Upload",
  data() {
    return {
      imageData: null,
      picture: null,
      uploadValue: 0,
      products: []
    };
  },
  computed:{
    ...mapGetters(["productsList"])

  },
  methods: {
    syncSearch(){
      // const data = await listProducts()
      console.log(this.productsList)
    },
    test_01() {
      listProducts().then(res => {
        this.products = res.map(item => ({
          category: item.category[0],
          // collection: item.collection,
          color: item.color,
          customization_available: item.customizationAvailable,
          description: item.description,
          details: item.details,
          dsg_code: item.dsgCode,
          images: item.images,
          name: item.name,
          outfit_full_length: item.outfitFullLength,
          price: item.price[0],
          sizes: item.sizes
        }))
      })
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },

    onUpload() {
      this.picture = null;
      const storageRef = firebase
        .storage()
        .ref(`${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
    },
  },
};
</script>


<style scoped="">
img.preview {
  width: 200px;
}
</style>
<template>
  <div class="carousel">
    <v-container>
      <!-- <v-row>
        <v-col>
          <v-radio-group v-model="previewMode" row> -->
            <!-- <v-radio label="Desktop" value="desktop"></v-radio> -->
            <!-- <v-radio label="Mobile" value="mobile"></v-radio> -->
            <!-- <v-radio label="Tablet" value="tablet"></v-radio> -->
          <!-- </v-radio-group>
        </v-col>
      </v-row> -->
      <!-- {{ carouselArr }} -->
      <!-- {{ uploading }} -->
      <!-- {{ carousel }} -->
      <v-row class="mt-3">
        <v-col md="12">
          <p class="text-center">
            Dimentions - 1920px x 1080px
          </p>          
          <v-row>
            <v-col
              cols="12"
              md="4"
              v-for="(carousel, index) in carouselArr"
              :key="carousel.id"
            >
              <v-card width="100%" class="mb-3">
                <v-row no-gutters>
                  <v-col md="4">
                    <v-img
                      max-height="140"
                      :src="
                        !!uploaded[index].desktopUrl
                          ? uploaded[index].desktopUrl
                          : carousel.desktopUrl
                      "
                      class="d-flex fill-height grey lighten-3"
                    >
                      <v-btn
                        dark
                        v-if="!!uploaded[index].desktopUrl || !!carouselArr[index].desktopUrl"
                        @click="deleteImage(index)"
                        icon
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-img>
                  </v-col>
                  <v-col md="8">
                    <v-card-text>
                      <form action="">
                        <div class="d-flex justify-center flex-column">
                          <v-file-input
                            truncate-length="37"
                            label="Select File"
                            :disabled="uploading.includes(index)"
                            :loading="uploading.includes(index)"
                            @change="onFileSelected($event, index)"
                          ></v-file-input>
                          <v-btn
                            :disabled="
                              index !== selectedCarouselIndex ||
                              uploading.includes(index)
                            "
                            class="ml-3"
                            color="primary"
                            @click="addCarousel(index)"
                            >Upload</v-btn
                          >
                        </div>
                      </form>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="8">
          <div class="preview">

          </div>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            :disabled="!changesList.length"
            text
            class="mr-1"
            @click="cancelCarouselUpload"
            >CANCEL</v-btn
          >
          <v-btn
            :loading="saving"
            :disabled="!changesList.length"
            depressed
            class="primary ml-1"
            @click="saveCarouselUpload"
            >SAVE</v-btn
          >
        </v-col>
      </v-row>

      <!-- FLASH MESSAGE -->
      <!-- <v-row>
        <v-col md="12">
          <h2 class="mb-3">Flash Messages</h2>
          <div v-for="flash in flashMsgs" :key="flash.main_text">
              <v-card class="pa-2 d-flex mb-1 justify-space-between">
                  <div>

                  <b>{{  flash.main_text }}</b> <br>
                  <span>{{ flash.sub_text }}</span>
                  </div>
                  <div>X</div>
              </v-card>
          </div>
          <hr class="my-5">
          <div class="px-3">
            <form action="" ref="inputForm">
                <v-text-field label="Top-Message" v-model="flashMsg.main_text" > </v-text-field>
                <v-text-field label="Sub-Message" v-model="flashMsg.sub_text" > </v-text-field>
                <v-btn color="primary" @click="addFlashMsg()">Submit</v-btn>
            </form>
            {{ flashMsgs }}
          </div>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { setCarousel, getCarousel, deleteCarousel } from "./../api/carousel";
import { uploadThroughApi } from "./../services/Upload";
import _ from "lodash"
export default {
  data() {
    return {
      draft: true,
      previewMode: "desktop",
      carouselArr: [],
      saving: false,
      selectedImage: null,
      uploading: [],
      uploaded: [],
      selectedCarouselIndex: null,
      previewImage: null,
      flashMsgs: [],
      flashMsg: {
        main_text: "",
        sub_text: "",
      },
    };
  },
  created() {
    this.carouselArr = this.blankCarousel();
    this.uploaded = this.blankCarousel();
    getCarousel().then((res) => {
      res.forEach((item) => (this.carouselArr[item.id] = item));
      this.$forceUpdate();
    });
  },
  computed: {
    changesList() {
      return this.uploaded.filter((item) => item[`${this.previewMode}Url`] !== "");
    },
  },
  methods: {
    onFileSelected(e, carouselIndex) {
      if (e == null) {
        return;
      }
      this.selectedImage = e;
      this.selectedCarouselIndex = carouselIndex;
      this.previewImage = URL.createObjectURL(this.selectedImage);
    },
    uploadFile(image) {
      // console.log(image);
      // return uploadThroughApi(image)

      const formData = new FormData();
      formData.append("file", image);
      formData.append("folder", "carousel");
      formData.append("size", 1800)
      // return 'url of image'
      return axios
        .post("https://harshita-jain.df.r.appspot.com/upload/", formData)
        .then((res) => res.data.url)
        .catch((err) => console.log(err));
    },
    async addCarousel(index) {
      console.log('hello', index)
      this.uploading.push(index);
      const url = await this.uploadFile(this.selectedImage);
      
      // console.log(this.uploaded[index])
      let urlKey = `${this.previewMode}Url`
      this.uploaded[index][urlKey] = url
      // console.log(this.uploaded[index])
      this.uploading = this.uploading.filter((item) => item !== index);
      this.selectedCarouselIndex = null;
      this.$setSnackbar("Upload Successful");
     
      // const url = await this.uploadFile(this.selectedImage);
      // this.uploading = this.uploading.filter((item) => item !== index);
      // this.uploaded[index]['desktop'] = url;
    },
    deleteImage(carouselIndex) {
      // this.uploaded[carouselIndex].desktopUrl = "";
      console.log(carouselIndex)
      deleteCarousel(carouselIndex.toString())
      .then(()=> {
        this.$setSnackbar("Carousel Deleted Successfully")
        
      // this.$forceUpdate();
      })
    },

    async saveCarouselUpload() {
      
      this.saving = true;
      for (let item of this.changesList) {

        
        // console.log(item[`${this.previewMode}Url`])
        this.carouselArr[item.id] = {...this.carouselArr[item.id],[`${this.previewMode}Url`]: item[`${this.previewMode}Url`]}
        console.log('aaa', this.carouselArr[item.id])
        // await setCarousel(`${item.id}`, this.carouselArr[item.id], this.draft);

        await setCarousel(`${item.id}`, this.carouselArr[item.id], false)
    
      }
      this.saving = false;
       this.uploaded = this.blankCarousel();
      this.$forceUpdate();
      this.$setSnackbar("Saved Successfully");
    },
    cancelCarouselUpload() {
      this.uploaded = this.blankCarousel();
    },
    addFlashMsg() {
      this.flashMsgs.push(Object.assign({}, this.flashMsg));
      // this.$refs.inputForm.reset();
      this.flashMsg.main_text = "";
      this.flashMsg.sub_text = "";
    },
    blankCarousel() {
      return [
        { id: 0, url: "", desktopUrl: "" },
        { id: 1, url: "", desktopUrl: "" },
        { id: 2, url: "", desktopUrl: "" },
      ];
    },
  },
};
</script>
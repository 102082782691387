import { doc, collection, getDoc, orderBy, query, updateDoc, getDocs, deleteDoc, setDoc, serverTimestamp } from 'firebase/firestore'
import { db, categoriesRef } from '../services/Database'

// const categoriesRef = collection(db, "Clients", "MOH", "Categories")
// const categoriesRef = db.collection('catgories')

export async function getCategoriesList() {

    let res = []
    // return categoriesRef.orderBy('timestamp', 'asc').get()
    return categoriesRef.get()
      .then(snaps => {
        console.log(snaps.docs.length)
        snaps.forEach(snap => res.push(snap.data()))
        return res
      })
    
    
}

export function setCollectionsItem(id, data) {
    return id == null? addCategory(data) : updateCategory(id, data)
}

export async function getCategory(id) {
    let ref = categoryRef(id)
    let snap = await getDoc(ref)
    return snap.data()
}

export function addCategory(data) {
    let docId = doc(categoriesRef).id

    setDoc(doc(categoriesRef, docId), { ...data, timestamp: Date.now(), id: docId },)
    .then(() => {
      console.log('Added User Data')
    })
    .catch(err => console.log(err))  
}

export function updateCategory(id, data) {
    let ref = categoryRef(id)
    return updateDoc(ref, data)
}

export function removeCategory(id) {
    let ref = categoryRef(id)
    return deleteDoc(ref)
}

function categoryRef(id) {
    return doc(db, "Clients/MOH/Categories", id)
}
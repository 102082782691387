<template>
  <div class="new-orders">
    <v-container>
      <v-row>
        <v-col md="12">
          <h2>
            New Orders
          </h2>
          {{ products }}
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <template>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
              :loading="false"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.payment="{ item }">
                <v-chip :color="getColor(item.payment)" dark small>
                  {{ item.payment }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- Dialog or Modal -->
                <v-dialog persistent transition="dialog-bottom-transition" max-width="900">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    elevation="0"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="getDetails()"
                      >
                      mdi-open-in-new
                      <!-- <v-icon small class="mr-2">
                  mdi-open-in-new
                </v-icon> -->
                      </v-icon
                    >
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark
                        >Order ID #3045621</v-toolbar
                      >
                      <v-card-text>
                       
                        <v-row class="mt-1">
                          <v-col md="12">
                            <v-card flat class="pa-2 product-card">
                              <v-list two-line>
                                <template v-for="(i,index) in 3" > 
                                <v-list-item
                                :key="index"
                                >
                                  <v-list-item-avatar>
                                    <v-img
                                      alt=""
                                      src="https://cdn.vuetifyjs.com/images/lists/1.jpg"
                                    ></v-img>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title>Product Name</v-list-item-title>
                                    <v-list-item-subtitle>Quanty: 1 , Size : XL</v-list-item-subtitle>
                                  </v-list-item-content>
                                    
                                  <v-list-item-content class="text-right">
                                    <div>
                                      Rs 20000
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  :key="index"
                                ></v-divider>
                                </template>
                                <v-list-item>
                                  <v-list-item-content class="text-right">
                                    <div>
                                     <strong> Total:</strong> Rs 60000
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="4">
                            <v-card class="pa-2 fill-height" outlined>
                              <h3 class="mb-1 overline">Contact Information</h3>
                              Email : email@gmail.com <br>
                              Phone No : 91-987654321
                            </v-card>
                          </v-col>
                          <v-col md="4">
                            <v-card class="pa-2 fill-height" outlined>
                              <h3 class="mb-1 overline">Shipping Address</h3>
                              R.G Road, Some Path <br>
                              Pin Code -  781003, Guwahati <br>
                              Assam, India
                            </v-card>
                          </v-col>
                          <v-col md="4">
                            <v-card class="pa-2 fill-height" outlined>
                              <h3 class="mb-1 overline">Payment Details</h3>
                              <strong class="grey--text">Payment Id</strong> #587238236 <br>
                              <strong class="grey--text">Payment Methods</strong> UPI <br>
                              More Pyement Details
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                 <!-- Dialog or Modal -->
                <!-- <v-icon small class="mr-2" @click="editItem(item)">
                  
                  mdi-open-in-new
                </v-icon> -->
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog or Modal -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      headers: [
        {
          text: "Order Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Product", value: "product" },
        { text: "Quantity", value: "qty" },
        { text: "Date", value: "date" },
        { text: "Email", value: "email" },
        { text: "Payment", value: "payment" },
        { text: "Action", value: "actions", sortable: false },
      ],
      desserts: [
        {
          id: 123454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          action: "open",
          payment: "Paid",
        },
        {
          id: 123454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          action: "open",
          payment: "Paid",
        },
        {
          id: 123454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          action: "open",
          payment: "pending",
        },
      ],
    };
  },
  methods: {
    getColor(payment) {
      if (payment.toLowerCase() == "paid") return "green";
      else return "red";
    },
    getDetails() {
      // alert("hasdasd");
    },
  },
  computed:{
    ...mapGetters(['products'])
  }
};
</script>


<style scoped>
.product-card span{ 
  font-weight: bold;
  
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Jewellery from '../views/Jewellery.vue'
import Clothes from '../views/Clothes.vue'
import NewOrders from '../views/NewOrders.vue'
import Shipped from '../views/Shipped.vue'
import Home from '../views/Home.vue'
import AddProduct from '../views/AddProduct.vue'
import UpdateProduct from './../views/UpdateProduct.vue'
import Carousel from '../views/Carousel.vue'
import Featured from '../views/Featured.vue'
import Orders from './../views/Orders.vue'
import FlashMessage from './../views/FlashMessage.vue'
import Collections from './../views/Collections.vue'
import Products from './../views/Products.vue'
import HomeBanners from './../views/HomeBanners.vue'
import Login from './../views/Login.vue'
import Uploadtest from './../views/Uploadtest.vue'
import AddItemTest from './../views/AddItemTest.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jewellery',
    name: 'Jewellery',
    component: Jewellery,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-item-test',
    name: 'AddItemTest',
    component: AddItemTest,
  },

  {
    path: '/edit-product/:id',
    name: "Edit Product",
    component: AddItemTest
  },
  {
    path: '/clothing',
    name: 'Clothes',
    component: Clothes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-orders',
    name: 'NewOrders',
    component: NewOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/shipped-orders',
    name: 'Shipped',
    component: Shipped,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-product',
    name: 'AddProduct',
    component: AddItemTest,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home-banners',
    name: 'HomeBanners',
    component: HomeBanners,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update-product/:id',
    name: 'UpdateProduct',
    component: UpdateProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: Carousel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/flash-message',
    name: 'Flash Message',
    component: FlashMessage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/featured',
    name: 'Featured',
    component: Featured,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/upload-test',
    name: 'Uploadtest',
    component: Uploadtest,
  },
 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

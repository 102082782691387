<template>
  <div class="dashboard-login fill-height">
    <v-container fill-height>
      <v-layout align-center justify-center>
        <v-flex sm12 md4>
          <h1 class="display-1 text-center" style="margin-bottom:70px;">
            DASHBOARD LOGIN
          </h1>
          <v-card class="elevation-12 pb-2">
            <v-card-title
              style="margin:auto -4px;"
              class="text-center pt-0"
              primary-title
            >
              <v-layout class="mb-5" row wrap>
                <v-flex class="grey lighten-4 py-4" xs12>
                  <!-- <img class="mt-4" src="../assets/logo1.png" width="150">                 -->
                  <h2 class="title">HARSHITA JAIN</h2>
                </v-flex>
                <v-flex class="mt-5 " xs12>
                  <h4 class="grey--text text--darken-3 font-weight-light">
                    Sign in to your account
                  </h4>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-form @submit.prevent="signIn()">
              <v-card-text>
                <v-text-field
                  label="Login"
                  name="login"
                  v-model="credentials.email"
                  prepend-inner-icon="mdi-account"                  
                  type="text"
                ></v-text-field>

                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  v-model="credentials.password"
                  prepend-inner-icon="mdi-lock"
                  type="password"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-container class="pa-0" grid-list-xs>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        type="submit"
                        :loading="signingIn"
                        style="margin-top:-20px;"
                        block
                        color="primary"
                        >Login</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { signIn } from "./../services/Auth";

export default {
  data: () => ({
    signingIn: false,
    credentials: {
      email: "",
      password: "",
    },
  }),
  methods: {
    signIn() {
      this.signingIn = true;
      signIn(this.credentials)
        .then((user) => {
          console.log(user);
          // this.$store.commit("SET_HIDE_APP", true)
          this.$store.commit("SET_SNACKBAR", {
            message: "Logged in successfully",
          });
          window.location.href = "/";
          // this.$router.replace("/")
        })
        .catch((err) => {
          this.signingIn = false;
          this.$store.commit("SET_SNACKBAR", {
            message: "Wrong email and password combination.",
          });
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.dashboard-login {
  background: url("https://stmed.net/sites/default/files/blue-white-wallpapers-25171-712462.jpg");
  background-size: cover;
}
</style>

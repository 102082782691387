import { ordersRef } from "../services/Database"

export function getOrders(){
    let allOrders =[];
    return ordersRef.get()
        .then(snaps => {
            snaps.forEach(snap => allOrders.push(snap.data()))
            return allOrders;
        })
}



// export function addProducts(data){
//     console.table(data)
//     // if(id == null) {
//     //     id = productsRef.doc().id
//     //     return productsRef.doc(id)
//     //       .set({ ...data, id: id, timestamp: Date.now() })    
//     //   } else {
//     //     return productsRef.doc(id)
//     //     .update(data)
//     //   }
// }
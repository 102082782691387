<template>
  <div class="new-orders">
    <!-- {{ orders  }} -->
    <v-container>
      <v-row>
        <v-col md="12">
          <template>
            <v-data-table
              :headers="headers"
              :items="orders"
              :items-per-page="5"
              class="elevation-1"
              :sort-by="['timestamp']"
              :sort-desc="['true']"
              v-if="!!orders.length"
              loading-text="Loading... Please wait"
            >
              
              <template v-slot:item.paymentStatus="{ item }">
                <v-chip :color="getColor(item.paymentStatus)" dark small>
                  {{ item.paymentStatus }}
                </v-chip>
              </template>
              <template v-slot:item.personInfo="{ item }">
               
                  {{ item.personInfo.firstName }}
                  {{ item.personInfo.lastName }}
          
              </template>
              <!-- <template v-slot:item.personInfo="{ item }">
               
                  {{ item.personInfo.phoneNumber }}
          
              </template> -->
              <template v-slot:item.timestamp="{ item }">
               
                 {{ formatTime(item.timestamp) }} 
          
              </template>

              <template v-slot:item.actions="{ item }">
                <!-- Dialog or Modal -->
                <v-dialog persistent transition="dialog-bottom-transition" max-width="900">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="getDetails(item.id)"
                      >
                      <v-icon left>mdi-eye</v-icon>
                      VIEW  
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar flat color="primary" 
                        >
                        <h3 class="title">Order # {{ currentOrder.oid }}</h3> 
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog.value = false" icon>
                          <v-icon>
                            mdi-close  
                          </v-icon>  
                        </v-btn>               
                      </v-toolbar>
                      <v-card-text>                       
                        <v-row class="mt-1">
                          <v-col md="12">
                            <v-card flat class="pa-2 product-card">
                              <v-list three-line>
                                <template v-for="(item,index) in currentOrder.items" > 
                                <v-list-item
                                :key="`item-${index}`"
                                >
                                  <v-list-item-avatar>
                                    <v-img
                                      alt=""
                                      :src="item.images[0]"
                                    ></v-img>
                                  </v-list-item-avatar>
                                  
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>Qty: {{ item.quantity }} , Size : {{ item.size.toString() }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ !!item.additionalInstructions? `"${item.additionalInstructions}""` : "No custom instructions" }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                    
                                  <v-list-item-content class="text-right">
                                    <div>
                                      Rs {{ item.price }}
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  :key="index"
                                ></v-divider>
                                </template>
                                <v-list-item>
                                  <v-list-item-content>
                                    <div class="d-flex mb-3">
                                      <strong>Sub-total:</strong>
                                      <v-spacer></v-spacer>
                                      <span>Rs {{ currentOrder.subtotal }}</span>
                                    </div>
                                    <div class="d-flex mb-3">
                                      <strong>Shipping:</strong>
                                      <v-spacer></v-spacer>
                                      <span>Rs {{ currentOrder.deliveryCharge }}</span>
                                    </div>
                                    <div class="d-flex">
                                     <strong> Total:</strong>
                                      <v-spacer></v-spacer>
                                      <span>Rs {{ currentOrder.total }}</span>
                                    </div>
                                    <!-- {{ currentOrder }} -->
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="4">
                            <v-card class="fill-height" outlined>
                              <v-card-text>
                                <h3 class="mb-1 overline">Contact Information</h3>
                                 {{ currentOrder.personInfo.firstName }}
                                {{ currentOrder.personInfo.lastName }},
                                <br>
                                Email : {{ currentOrder.personInfo.email }} <br>
                                
                                Phone No : {{ currentOrder.personInfo.phoneNumber }}
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col md="4">
                            <v-card class="fill-height" outlined>
                              <v-card-text>
                                <h3 class="mb-1 overline">Shipping Address</h3>
                                {{ currentOrder.personInfo.firstName }} 
                                {{ currentOrder.personInfo.lastName }},
                                <br>
                                {{ currentOrder.personInfo.address }},
                                {{ currentOrder.personInfo.city }},
                                {{ currentOrder.personInfo.state }},
                                {{ currentOrder.personInfo.pinCode }},
                                {{ currentOrder.personInfo.country }},


                                <br>
                                <span v-if="currentOrder.personInfo.optionalAddress"> {{ currentOrder.personInfo.optionalAddress }}</span>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col md="4">
                            <v-card class="fill-height" outlined>
                              <v-card-text>
                                <h3 class="mb-1 overline">Payment Details</h3>
                                <strong class="grey--text">Payment Id:</strong> #{{ currentOrder.paymentOrderId }} <br>
                                <strong class="grey--text">Payment Method:</strong> UPI <br>
                                <v-card-actions class="justify-center">
                                <v-btn color="primary" small text>MORE DETAILS <v-icon right>mdi-open-in-new</v-icon> </v-btn>
                                </v-card-actions>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!-- <v-card-actions class="justify-end">
                      </v-card-actions> -->
                    </v-card>
                  </template>
                </v-dialog>
                  <v-btn class="ml-2" color="primary" dark small depressed>
                    <v-icon left>mdi-open-in-new</v-icon> TRACK
                  </v-btn>
                 <!-- Dialog or Modal -->
                <!-- <v-icon small class="mr-2" @click="editItem(item)">
                  
                  mdi-open-in-new
                </v-icon> -->
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog or Modal -->

  </div>
</template>

<script>
import { format } from 'fecha';
export default {
  data() {
    return {
      currentOrder: [],
      headers: [
        {
          text: "Order #",
          align: "start",
          sortable: false,
          value: "oid",
        },
        { text: "Product(s)", value: "items[0].name" },
        // { text: "Total Qty", value: "qty" },
        { text: "Ordered On", value: "timestamp" },
        // { text: "Email/Contact", value: "email" },
        { text: "Payment Status", value: "paymentStatus" },
        { text: "Name", value: "personInfo"},
        { text: "Phone", value: "personInfo.phoneNumber" },
        { text: "Total", value: "total" },
        { text: "Action", value: "actions", sortable: false },
      ],
      desserts: [
        {
          id: 124454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          amount: 2000,
          action: "open",
          payment: "Paid",
        },
        {
          id: 11454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          amount: 2000,
          action: "open",
          payment: "Paid",
        },
        {
          id: 123454,
          product: "Clothing Item",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          amount: 2000,
          action: "open",
          payment: "pending",
        },
      ],
    };
  },
  methods: {
    getColor(payment) {
      if (payment.toLowerCase() == "paid") return "green";
      else return "blue";
    },
    getDetails(id) {
     this.currentOrder =  this.orders.find(item => item.id == id)
      // alert("hasdasd");
    },
    formatTime( timestamp ){
      return format(new Date(timestamp), 'mediumDate');
    }
  },
  props:{
    orders:{
      type: Array
    }
  }
};
</script>


<style scoped>
.product-card span{ 
  /* font-weight: bold; */
  
}
</style>s
<template>
  <div class="add-product">
    <v-container>
      <v-row>
        <v-col>
          <h1 class="headline text-center mt-3">Add new product</h1>
        
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9">
          <div>
            <v-form ref="form">
              <v-text-field
                v-model="product.name"
                label="Name"
                required
                outlined
                clearable
                dense
              ></v-text-field>

              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="product.dsgCode"
                    label="DSG CODE"
                    required
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.startingPrice"
                    label="Price"
                    prefix="₹"
                    required
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.color"
                    label="Color"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-select
                :items="categories"
                v-model="selectedCategory"
                label="Category"
                required
                outlined
                clearable
                dense
              ></v-select>

              <v-textarea
                v-model="product.description"
                outlined
                clearable
                dense
                label="Product Description"
              >
              </v-textarea>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="product.details[0].value"
                    label="Fabric"
                    required
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.outfitFullLength"
                    label="Outfit Full Length"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.color"
                    label="Color"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="product.details[1].value"
                    label="Delivery Time"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.details[2].value"
                    label="Wash Care"
                    required
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="product.details[3].value"
                    :items="availability"
                    label="Availability"
                    required
                    outlined
                    clearable
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <h4 class="mb-4">Select Collection</h4>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="product.collection"
                    :items="collectionName"
                    dense
                    color="blue-grey lighten-2"
                    label="Select"
                    item-text="name"
                    item-value="name"
                    multiple
                    outlined
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        color="primary"
                        text-color="black"
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <h4 class="mb-2">Add Product Images</h4>
              <!-- PRODUCTS IMAGES UPLOAD -->
              <v-row>
                <v-col v-for="(img, index) in images" md="3" :key="`productImg-${index}`">
                  <v-card color="pa-2 pb-4">
                    <v-img
                      class="mb-4 primary"
                      position="top"
                      contain
                      :src="previewImages[index]"
                      height="300"
                    ></v-img>
                    <v-file-input
                      @change="uploadImg($event, index)"
                      label="File input"
                      accept="image/*"
                      outlined
                      dense
                      hide-details
                      @click:clear="removeImg(index)"
                    ></v-file-input>                    
                  </v-card>
                </v-col>                                
              </v-row>
            </v-form>
          </div>
        </v-col>
        <v-col md="3">
          <!-- <p class="body-2">First image is the product display image</p>
          <v-card flat class="grey lighten-3" height="300px">
            <v-img height="100%" src=""></v-img>
          </v-card> -->

          <!-- ADDITIONAL OPTIONS -->
          <div class="d-flex align-center">
            <div>
              <h3 class="mr-2 mb-0">Customization Available:</h3>
            </div>
            <v-switch v-model="product.customizationAvailable"></v-switch>
          </div>
          <v-chip
            color="green"
            small
            outlined
            v-if="product.customizationAvailable"
            >Available</v-chip
          >
          <v-chip color="red" small outlined v-else>Not Available</v-chip>

          <!-- TAGS OF PRODUCTS -->
          <h4 class="mt-5 mb-3">Product Tags</h4>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="product.tags"
                :items="product.tags"
                dense
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="name"
                multiple
                outlined
              >
                <template v-slot:selection="data">
                  <v-chip
                    color="primary"
                    text-color="black"
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-center" cols="12">
          <v-btn @click="handleFileUpload()">Upload Images</v-btn>
          <v-btn color="success" class="mr-4" @click="onAdd()">
            <v-icon left>mdi-plus</v-icon>
            Add product
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { addProducts } from "./../api/product";
import { triggerAction } from './../services/common'
import axios from "axios";
export default {
  data: () => ({

    
    collectionName: ["New Arrivals", "hello"],

    product: {},
    products: [],
    row: "radio-1",
    // name: "",
    // tags: [],
    // category: [],
    // description: "",
    // images: [],
    // price: [],
    // size: [],
    // isInStock: true,
    // defaultQty: 1,
    // collection: [],
    // details: [],
    selectedCategory: "Lehenga Sets",
    productData: {
      availability: "Made to order",
    },
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    availability: ["Made to order", "Ready to ship"],
    categories: [
      "Lehenga Sets",
      "Jumpsuits",
      "Co-ords",
      "Skirts",
      "Dresses",
      "Tops",
      "Accessories",
      "Pants",
      "Shirts",
    ],
    images: ['','',''],
    previewImages: ['','',''],
    currentImage: null,
  }),
  computed: {
  },
  methods: {
    uploadImg(img, index) {      
      if (!img) { return }              
      this.previewImages[index] = URL.createObjectURL(img)
      this.images[index] = img
      this.$forceUpdate()
    },
    remove(item) {
      this.product.collection = this.product.collection.filter(
        (el) => el != item
      );
    },
    //REMOVE IMAGE AND PREVIEW IMAGES
    removeImg(index) {
      [this.previewImages[index], this.images[index]] = ['', '']
      this.$forceUpdate()
    },
    async handleFileUpload() {
      for (let image of this.images) {
        var imgUrl = await this.uploadFile(image);
        this.product.images.push(imgUrl);

        console.log(imgUrl);
      }
    },
    uploadFile(image) {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("folder", "demo");
      return axios
        .post("http://localhost:9000/upload", formData)
        .then((res) => res.data.url)
        .catch((err) => console.log(err));
    },

    async onAdd() {
      // addProducts(this.product);
      const product = this.runProductAction();
      console.log(product);
      return;
      // await addProducts(product)
    },
    runProductAction() {
      this.product.category = [this.selectedCategory];
      let _product = {
        ...this.product,
        category_slugs: this.product.category.map((item) =>
          item.split(" ").join("-").toLowerCase().toLowerCase()
        ),
        collection_slugs: this.product.collection.map((item) =>
          item.split(" ").join("-").toLowerCase().toLowerCase()
        ),
        slug: this.product.name.split(" ").join("-").toLowerCase(),
        price: Array.from({ length: 7 }, (_, i) => this.product.startingPrice),
        isLive: true,
      };
      return _product;
    },
    setProductTemplate() {
      this.product = {
        id: "new product",
        name: "",
        collection: ["New Arrivals"],
        category: [],
        sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
        defaultSize: "S",
        defaultQty: 1,
        images: [],
        description: "",
        dsgCode: "",
        isInStock: true,
        price: null,
        color: "",
        customizationAvailable: true,
        startingPrice: null,
        details: [
          {
            title: "Fabric",
            value: "",
          },
          {
            title: "Shipping Time",
            value: "2-3 Weeks",
          },
          {
            title: "Wash Care",
            value: "Dry Clean, Steam Iron",
          },
          {
            title: "Availablity",
            value: "",
          },
        ],
        tags: ["isFeatured", "new-arrival", "dress"],
      };
    },
  },

  created() {
    triggerAction("showLoading", "Uploading...")
    this.setProductTemplate();
  },
};
</script>

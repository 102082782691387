<template>
  <div class="add-product">
    <v-container>
      <v-row>
        <v-col lg="9" md="12" class="d-flex justify-space-between">
          <h1 class="title mt-3">{{ isEdit ? "Edit" : "Add" }} Product</h1>
          <v-switch v-model="isCompact" label="Compact"></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="9" md="12">
          <div>
            <v-form ref="form">
              <v-text-field v-model="product.name" label="Name" required outlined dense></v-text-field>
              <v-row>
                <v-col cols="6" md="4">
                  <v-text-field v-model="product.dsgCode" label="DSG CODE" required outlined clearable dense
                    hide-details>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field v-model.number="product.price" label="Price" prefix="₹" required outlined dense
                    hide-details>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select :items="categoryNames" v-model="selectedCategory" label="Category" hide-details required
                    outlined clearable dense></v-select>
                </v-col>
                <v-col md="12">
                  <v-textarea v-model="product.description" outlined dense label="Product Description" rows="2"
                    hide-details>
                  </v-textarea>
                </v-col>
                <!-- <v-col md="4">
                  <v-text-field
                    v-model="product.color"
                    label="Color"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col> -->
              </v-row>

              <!-- <v-textarea
                v-model="product.description"
                outlined
                clearable
                dense
                label="Product Description"
              >
              </v-textarea> -->
              <v-row v-if="!isCompact">
                <v-col cols="6" md="4">
                  <v-text-field v-model="product.details[0].value" label="Fabric" required outlined clearable dense
                    hide-details>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field v-model="product.outfitFullLength" label="Outfit Full Length" required outlined
                    clearable hide-details dense :disabled="product.isCompact"></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field v-model="product.color" label="Color" hide-details required outlined clearable
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="4" v-if="!isCompact">
                  <v-text-field v-model="product.details[1].value" label="Delivery Time" required outlined clearable
                    hide-details dense></v-text-field>
                </v-col>
                <v-col cols="6" md="4" v-if="!isCompact">
                  <v-text-field v-model="product.details[2].value" label="Wash Care" required outlined clearable
                    hide-details dense>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <!-- <v-select
                    v-model="product.details[3].value"
                    :items="availability"
                    label="Availability"
                    required
                    hide-details
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <!-- {{ product.details[3].value }} -->

                  <v-select :items="product.details[3].value" label="Availability" required hide-details outlined
                    clearable dense>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @mousedown.prevent @click="">
                        <v-list-item-content>
                          <v-list-item-title class="pl-4">
                            ALL
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-select dense value="Made to Order" outlined hide-details
                            :items="['Made to Order', 'Ready to Ship']" @change="setAllAvailability($event)"></v-select>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item class="align-center" @mousedown.prevent @click="">
                        <v-list-item-content>
                          <v-list-item-title class="pl-4">
                            {{ data.item.size }}
                          </v-list-item-title>
                        </v-list-item-content>
                        
                        <v-list-item-action>
                          <v-select
                           dense 
                           v-model="data.item.availability" 
                           
                           outlined 
                           hide-details
                            :items="['Made to order', 'Ready to Ship']"></v-select>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <!-- *** PRODUCT COLLECTIONS *** -->
                <v-col cols="6" md="12">
                  <v-autocomplete v-model="product.collection" :items="collectionName" dense color="blue-grey lighten-2"
                    label="Collection(s)" item-text="name" item-value="name" multiple outlined hide-details
                    hide-selected>
                    <template v-slot:selection="data">
                      <v-chip color="primary" class="text--primary" small v-bind="data.attrs"
                        :input-value="data.selected" close @click="data.select"
                        @click:close="remove(data.item, 'collection')">
                        {{ data.item }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content v-if="$typeEquals(data.item, 'object')"
                        v-text="data.item"></v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title v-html="data.item"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- RELATED PRODUCTS -->
                <v-col cols="12" md="12">
                  <v-autocomplete v-model="product.relatedItems" :items="relatedProductsList"
                    :dense="!product.relatedItems.length" @change="relatedItemsSearchText = ''"
                    :search-input.sync="relatedItemsSearchText" color="blue-grey lighten-2" label="Related Products"
                    item-text="name" item-value="id" multiple outlined hide-details hide-selected>
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                        @click:close="remove(data.item.id, 'relatedItems')">
                        <v-avatar left>
                          <v-img :src="data.item.image"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <v-img :src="data.item.image" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                  <h3 class="overline mb-4">COMPONENTS</h3>
                  <v-row>
                    <v-col cols="12" md="12" v-if="!!product.components.length">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">Price</th>
                              <th class="text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="component in product.components" :key="component.name">
                              <td>{{ component.name }}</td>
                              <td>{{ component.price }}</td>
                              <td>
                                <v-btn fab depressed icon @click="deleteItemComponent(component.id)">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="7" md="7">
                      <v-text-field dense outlined hide-details label="Component Name"
                        v-model="componentData.name"></v-text-field>
                    </v-col>
                    <v-col cols="3" md="3">
                      <v-text-field dense outlined hide-details label="Price"
                        v-model.number="componentData.price"></v-text-field>
                    </v-col>
                    <v-col cols="2" md="2">
                      <!-- <v-btn @click="addComponent" block depressed>CREATE</v-btn> -->
                      <v-btn small @click="addComponent" fab depressed color="primary">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col md="12">             
                  <v-autocomplete
                    v-model="product.tags"
                    :items="tags"
                    :loading="createTagLoading"
                    dense
                    color="blue-grey lighten-2"
                    label="Tag(s)"
                    item-text="name"
                    item-value="name"
                    :search-input.sync="tagSearchText"
                    multiple
                    outlined
                    hide-details
                    hide-selected
                    @change="onTagSelect"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        color="primary"
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item, 'tags')"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>

                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item"
                          ></v-list-item-title>
                        </v-list-item-content>
                      
                      </template>
                    </template>

                    <template v-slot:no-data>
                        <v-btn  @click="createNewTag" depressed class="ml-2">
                          Create "{{ tagSearchText }}"
                        </v-btn>
                    </template>

                  </v-autocomplete>
                </v-col>  -->

                <!-- <v-col v-if="!!product.features.length" cols="12">
                  <h5 class="overline mb-2">FEATURES</h5>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Name
                            </th>
                            <th class="text-left">
                              Options
                            </th>
                            <th class="text-left">
                              Actions
                            </th>                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="featureItem in product.features"
                            :key="featureItem.id"
                          >
                            <td>{{ featureItem.name }}</td>
                            <td>
                              <span v-for="option in featureItem.options" :key="`${featureItem.id}-${option}`">
                                {{ option }}
                              </span>
                            </td>
                            <td>
                              <v-btn @click="removeProductFeature(featureItem.id)" small icon>
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>             
                </v-col>   -->

                <!-- <v-col md="12">
                  <v-btn @click="showFeaturesDialog = true" small outlined>
                    ADD FEATURE
                  </v-btn>
                </v-col> -->

                <v-col md="3">
                  <v-checkbox v-model="isFeatured" label="Featured"></v-checkbox>
                </v-col>
                <v-col md="3">
                  <v-checkbox v-model="product.isInStock" label="In Stock"></v-checkbox>
                </v-col>
                <v-col md="3">
                  <v-checkbox v-model="product.isLive" label="Live"></v-checkbox>
                </v-col>
                <v-col md="3">
                  <v-checkbox v-model="product.appendSlugId" label="Auto Unique Link"></v-checkbox>
                </v-col>
              </v-row>

              <h4 class="mb-4">Add Product Images</h4>
              <!-- PRODUCTS IMAGES UPLOAD -->
              <v-row>
                <v-col v-for="(item, index) in 4" md="3" cols="6" :key="`productImg-${index}`">
                  <v-card color="pa-2 pb-4">
                    <v-img class="mb-4 grey lighten-2" position="top" :src="previewImages[index]" height="200">
                      <v-btn v-if="!!previewImages[index]" small depressed bottom fab class="ml-2 mt-2" :loading="false"
                        @click="removeImg(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-img>

                    <v-file-input @change="uploadImg($event, index)" label="File input" accept="image/*" outlined dense
                      hide-details @click:clear="removeImg(index)">
                      <template v-slot:selection="{ text }">
                        {{ fileInputText(text, index) }}
                      </template>
                    </v-file-input>
                  </v-card>
                </v-col>
                <v-col md="12">
                  <template v-if="imagesUploading">
                    <h5 class="overline">UPLOADING IMAGES</h5>
                    <v-progress-linear height="10" striped stream :buffer-value="0"
                      :value="uploadProgress"></v-progress-linear>
                  </template>
                  <v-btn v-else :loading="imagesUploading" :disabled="!imageSelected" @click="handleFileUpload()">Upload
                    Images</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>

        <!-- <v-col md="3">
          <p class="body-2">First image is the product display image</p>
          <v-card flat class="grey lighten-3" height="300px">
            <v-img height="100%" src=""></v-img>
          </v-card> -->

        <!-- ADDITIONAL OPTIONS -->
        <!-- <div class="d-flex align-center">
            <div>
              <h3 class="mr-2 mb-0">Customization Available:</h3>
            </div>
            <v-switch v-model="product.customizationAvailable"></v-switch>
          </div>
          <v-chip
            color="green"
            small
            outlined
            v-if="product.customizationAvailable"
            >Available</v-chip
          >
          <v-chip color="red" small outlined v-else>Not Available</v-chip> -->

        <!-- TAGS OF PRODUCTS -->
        <!-- <h4 class="mt-5 mb-3">Product Tags</h4>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="product.tags"
                :items="product.tags"
                dense
                color="blue-grey lighten-2"
                label="Select"
                item-text="name"
                item-value="name"
                multiple
                outlined
              >
                <template v-slot:selection="data">
                  <v-chip
                    color="primary"
                    text-color="black"
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col> -->

        <v-col class="text-center" md="12" lg="9">
          <v-btn v-if="isEdit && product.isLive" width="150" outlined large color="primary" class="mr-4" target="_blank"
            :href="`https://edge.harshitajain.co.in/products/${product.slug}`">
            <!-- <v-icon left>mdi-plus</v-icon> -->
            VIEW PRODUCT
          </v-btn>
          <v-btn :loading="addingProduct" width="150" depressed large color="success" :disabled="isDisabled"
            @click="handleAddProduct">
            <!-- <v-icon left>mdi-plus</v-icon> -->
            SUBMIT
          </v-btn>
          <!-- <v-btn @click="refreshPage()">Add New Product</v-btn> -->
          <v-btn v-if="isDisabled" @click="refreshPage()">Add New Product</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-if="!!features.length" v-model="showFeaturesDialog" max-width="360">
      <v-card class="pt-3 pb-2">
        <v-card-title class="mb-5 d-flex justify-space-between">
          <h5 class="text-h5">Add Feature</h5>
          <v-btn to="/additional" small color="success" depressed>CREATE NEW</v-btn>
        </v-card-title>

        <v-card-text>
          <v-select :items="selectableFeatures" item-text="name" item-value="id" outlined dense label="Select a Feature"
            @change="onFeatureSelect($event)">
            <!-- :value="features[0].id" -->
          </v-select>
          <v-row v-if="currentFeatureIndex !== null" align="center">
            <v-col class="d-flex justify-space-between" cols="12">
              <h5 class="overline">FEATURE OPTIONS</h5>
              <v-btn @click="selectAllFeatures" small text color="primary">SELECT ALL</v-btn>
            </v-col>
            <v-col md="12">
              <v-checkbox v-for="(option, index) in features[currentFeatureIndex].options" :key="`${option}-${index}`"
                v-model="currentFeatureOptionsChecked" :value="option" :label="option" class="mt-2 mr-2">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showFeaturesDialog = false"> CANCEL </v-btn>

          <v-btn :disabled="isProductFeatureSaveDisabled" color="primary" @click="saveProductFeature">
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // import { triggerAction } from './../services/common'
  import axios from "axios";
  // import { addTag, getTagsList } from './../api/tags'
  import {
    addProduct,
    getProduct,
    updateProduct,
    removeProduct,
  } from "./../api/product";
  import { getCategoriesList } from "./../api/categories.js";
  import { getCollections } from "./../api/collections";
  import randomstring from "randomstring";
  import { mapGetters } from "vuex";
  import { parse } from "fecha";
  // import { getFeaturesList } from "../api/features";

  export default {
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name == "Edit Product") {
          vm.$emit("refreshView");
        }
        next();
      });
    },
    data: () => ({
      isCompact: false,
      components: [],
      componentData: {
        name: null,
        price: null,
      },
      isDisabled: false,
      showFeaturesDialog: false,
      currentFeatureIndex: null,
      collectionName: [],
      categoryNames: [],
      tags: [],
      product: {},
      products: [],
      row: "radio-1",
      selectedCategory: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      availability: ["Made to order", "Ready to ship"],
      categories: [],
      images: ["", "", "", ""],
      previewImages: ["", "", "", ""],
      // imageFiles: [],
      currentImage: null,
      imageSelected: false,
      imagesUploading: false,
      tagSearchText: "",
      createTagLoading: false,
      addingProduct: false,
      features: [],
      currentFeatureOptionsSelected: [],
      currentFeatureOptionsChecked: [],
      relatedItemsSearchText: "",
      uploadedCount: 0,
      originalProductName: null
    }),
    computed: {
      ...mapGetters(["productsList"]),
      relatedProductsList() {
        return this.productsList.map((item) => ({
          id: item.id,
          name: item.name,
          image: item.images[0],
        }));
      },
      currentRoute() {
        return this.$route;
      },
      selectableFeatures() {
        let productFeatureIds = this.product.features.map((item) => item.id);
        return this.features.filter(
          (item) => !productFeatureIds.includes(item.id)
        );
      },
      isProductFeatureSaveDisabled() {
        return (
          this.currentFeatureIndex == null ||
          !this.currentFeatureOptionsChecked.length
        );
      },
      isEdit() {
        return this.currentRoute.name == "Edit Product";
      },
      uploadProgress() {
        return (this.uploadedCount / this.selectedImages()) * 100;
      },
      isFeatured: {
        get() {
          return this.product.tags.includes("isFeatured");
        },
        set(val) {
          this.product.tags =
            val == true
              ? [...this.product.tags, "isFeatured"]
              : this.product.tags.filter((el) => el !== "isFeatured");
        },
      },
    },
    methods: {
      selectedImages() {
        return this.previewImages.filter((el) => el.includes("blob")).length;
      },
      addComponent() {
        let id = randomstring.generate(10);
        this.product.components.push({ ...this.componentData, id });
        this.$nextTick(() => {
          this.componentData.name = "";
          this.componentData.price = null;
          this.$forceUpdate();
        });
      },
      fileInputText(text, index) {
        if (!!this.previewImages[index]) {
          return text;
        } else {
          return null;
        }
      },
      onTagSelect() {
        this.tagSearchText = "";
      },
      onFeatureSelect(featureId) {
        let featureIdList = this.features.map((item) => item.id);
        this.currentFeatureIndex = featureIdList.indexOf(featureId);
        this.currentFeatureOptionsChecked = [];
      },
      selectAllFeatures() {
        this.currentFeatureOptionsChecked =
          this.features[this.currentFeatureIndex].options;
      },
      uploadImg(img, index) {
        this.imageSelected = true;
        if (!img) {
          return;
        }
        this.previewImages[index] = URL.createObjectURL(img);
        this.images[index] = img;
        this.$forceUpdate();
      },
      removeProductFeature(featureId) {
        this.product.features = this.product.features.filter(
          (item) => item.id !== featureId
        );
      },
      saveProductFeature() {
        let _feature = Object.assign({}, this.features[this.currentFeatureIndex]);
        _feature.options = [...this.currentFeatureOptionsChecked];
        this.product.features.push(_feature);
        this.currentFeatureOptionsChecked = [];
        this.showFeaturesDialog = false;
        this.currentFeatureIndex = null;
      },
      async createNewTag() {
        this.createTagLoading = true;
        try {
          await addTag({
            name: this.tagSearchText,
            slug: this.$slugify(this.tagSearchText),
          });
          console.log("tag added");
        } catch (error) {
          console.log(error);
        }
        this.tags.push(this.tagSearchText);
        this.product.tags.push(this.tagSearchText);
        this.tagSearchText = "";
        this.createTagLoading = false;
        this.$forceUpdate();
      },
      remove(item, group) {
        this.product[group] = this.product[group].filter((el) => el != item);
      },

      deleteItemComponent(componentId) {
        this.product.components = this.product.components.filter(
          (item) => item.id != componentId
        );
        // console.log(product)
      },
      //REMOVE IMAGE AND PREVIEW IMAGES
      removeImg(index) {
        this.previewImages.splice(index, 1, "");
        this.images.splice(index, 1, "");
        this.product.images.splice(index, 1, "");
        if (this.selectedImages() == 0) {
          this.imageSelected = false;
        }
        this.$forceUpdate();
      },
      async handleFileUpload() {
        this.imagesUploading = true;
        // this.imageFiles = this.images.filter(
        //   (image) => !this.product.images.includes(image)
        // );

        // this.imageFiles = this.images;
        let hasError = false;

        for (let [index, value] of this.images.entries()) {
          if (typeof value == "object") {
            try {
              var imgUrl = await this.uploadFile(value);
              console.log("Upload Done")
              this.product.images[index] = imgUrl;
            } catch (error) {
              this.removeImg(index);

              this.$store.commit("SET_SNACKBAR", {
                message: "An image upload failed",
              });
              hasError = true;
            }

            this.uploadedCount += 1;
          } else {
            this.product.images[index] = value;
          }
        }

        this.imagesUploading = false;
        this.imageSelected = false;
        this.uploadedCount = 0;

        this.images = [...this.product.images];

        // if (!hasError) {
        //   this.$store.commit("SET_SNACKBAR", {
        //     message: "Uploaded Successfully",
        //   });
        // }
      },
      // uploadFile(image) {
      //   const formData = new FormData();
      //   formData.append("file", image);
      //   formData.append("folder", "product_images");
      //   return axios
      //     .post("https://harshita-jain.df.r.appspot.com/upload", formData)
      //     .then((res) => res.data.url)
      //     .catch((err) => console.log(err));
      // },
      uploadFile(image) {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", image);
          formData.append("folder", "product_images");

          setTimeout(() => {
            console.log("Uploading ...")
            axios
              .post("https://harshita-jain.df.r.appspot.com/upload", formData)
              .then((res) => resolve(res.data.url))
              .catch((err) => reject(err));
          }, 20000)

        })
      },
      async handleAddProduct() {
        // addProducts(this.product);
        this.addingProduct = true;
        var message = "Added Successfully";
        const product = this.runProductAction();
        // console.log(product);
        // return;
        if (this.isEdit) {
          await updateProduct(product, this.product.id);
          message = "Updated Successfully";
        } else {
          await addProduct(product);
        }
        this.addingProduct = false;
        this.$setSnackbar(message);

        if (!this.isEdit) {
          this.isDisabled = true;
        }
      },
      runProductAction() {
        this.product.category = [this.selectedCategory];

        let _product = {
          ...this.product,
          category_slugs: this.product.category.map((item) => {
            let categoryObj = this.categories.find((el) => el.name == item);
            return categoryObj.slug;
          }),
          collection_slugs: this.product.collection.map((item) =>
            this.$slugify(item)
          ),
          slug: this.isEdit == false ? this.$slugify(this.product.name, this.product.appendSlugId) : this.originalProductName == this.product.name && this.product.appendSlugId == false ? this.product.slug : this.$slugify(this.product.name, this.product.appendSlugId),
          // slug: this.$slugify(this.product.name, true),
          price: Array.from(
            { length: this.product.sizes.length },
            (_, i) => this.product.price
          ),
          isCompact: this.isCompact,
        };

        // remove any empty values from the end of the images array.
        let allowPop = true;
        for (let i = 3; i >= 0; i--) {
          if (_product.images[i] == "" && allowPop == true) _product.images.pop();
          else allowPop = false;
        }

        // _product["startingPrice"] = _product.price[1];
        return _product;
      },

      setProductTemplate() {
        this.product = {
          id: "new product",
          name: "",
          isCompact: false,
          collection: [],
          category: [],
          components: [],
          images: [],
          description: "",
          isInStock: true,
          price: null,
          customizationAvailable: true,
          details: [],
          features: [],
          appendSlugId: false,
          // isFeatured: false,
          isLive: true,
          tags: [],
          dsgCode: "",
          sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
          defaultSize: "S",
          defaultQty: 1,
          color: "",
          relatedItems: [],
          details: [
            {
              title: "Fabric",
              value: "",
            },
            {
              title: "Shipping Time",
              value: "2-3 Weeks",
            },
            {
              title: "Wash Care",
              value: "Dry Clean, Steam Iron",
            },
            {
              title: "Availablity",
              value: [
                { size: 'XS', availability: 'Ready to Ship' },
                { size: 'S', availability: 'Made to order' },
                { size: 'M', availability: 'Made to order' },
                { size: 'L', availability: 'Made to order' },
                { size: 'XL', availability: 'Made to order' },
                { size: 'XXL', availability: 'Made to order' },
                { size: 'XXXL', availability: 'Made to order' },
              ],
            },
          ],
          tags: [],
        };
      },

      refreshPage() {
        this.$emit("refreshView");
        // window.location.reload();
      },

      setAllAvailability(val) {
        console.log(val)
        this.product.details[3].value.forEach(item => {
          item.availability = val
        })
      },
      defaultSizeAvailabilityList(availability) {
        return [
          { size: 'XS', availability },
          { size: 'S', availability },
          { size: 'M', availability },
          { size: 'L', availability },
          { size: 'XL', availability },
          { size: 'XXL', availability },
          { size: 'XXXL', availability },
        ]
      },
    },

    created() {
      // triggerAction("showLoading", "Uploading...")
      this.setProductTemplate();

      getCollections().then((res) => {
        this.collectionName = res.map((item) => item.name);
      });

      getCategoriesList().then((res) => {
        this.categories = res;
        this.categoryNames = res.map((item) => item.name);
      });

      // getTagsList()
      //   .then((res) => {
      //     this.tags = res.map(item => item.name)
      //   })
      //   .catch(err => console.log(err))

      // getFeaturesList()
      //   .then(res => {
      //     this.features = res
      //     // this.currentFeatureOptionsSelected = [...Array.from({ length: this.features[0].options.length }, (_, i) => this.features[0].options[i])]
      //     // this.currentFeatureOptionsSelected = this.features.map(item => item.name)[0]
      //   })

      if (this.isEdit) {
        getProduct(this.currentRoute.params.id).then((res) => {
          this.product = res;
          if (typeof this.product.details[3].value == 'string') {
            this.product.details[3].value =  this.defaultSizeAvailabilityList(this.product.details[3].value)
          }
          this.originalProductName = this.product.name
          // this.product.isCompact = !!this.product.isCompact? this.product.isCompact : false

          this.isCompact = !!this.product.isCompact
            ? this.product.isCompact
            : false;
          this.product.price = this.product.price[0];
          this.product["components"] = !!this.product.components
            ? this.product.components
            : [];
          this.product["relatedItems"] = !!this.product.relatedItems
            ? this.product.relatedItems
            : [];
          this.images = [
            ...this.images.map((image, index) =>
              !!this.product.images[index] ? this.product.images[index] : image
            ),
          ];
          this.previewImages = [
            ...this.images.map((image, index) =>
              !!this.product.images[index] ? this.product.images[index] : image
            ),
          ];
          this.selectedCategory = this.product.category[0];
        });
      }
    },
  };
</script>
<template>
  <div class="clothes">
    <v-container>
      <v-row>
        <v-col md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mb-5"
            ></v-text-field>    
        </v-col>
        <v-col md="4">
          <v-select label="Filter" :items="['All', 'Featured', 'New Arrivals', 'Festive', 'Ready to Ship', 'Jewellery']" value="All"/>
        </v-col>
        <!-- <v-col md="2">
          <v-select label="Collection" :items="['All', 'Featured', 'New Arrivals']" value="All"/>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col md="12" class="mt-2">
            <template>
          <v-data-table
            dense
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
              :loading="false"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.image="{ item }">
                  <v-img class="my-2" :src="item.image" aspect-ratio="1" width="70px"></v-img>
              </template>
              <template v-slot:item.payment="{ item }">
                <v-chip :color="getColor(item.payment)" dark small>
                  {{ item.payment }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn depressed color="primary" dark small class="mr-2" @click="editItem(item)">
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn depressed color="primary" dark small @click="deleteItem(item)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-col>        
      </v-row>
    </v-container>

    <v-btn to="/add-product" fab dark large color="primary" fixed right bottom>
        <v-icon dark>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>


<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
        },
        { text: "Product Name", value: "product_name" },
        { text: "Category", value: "category" },
        { text: "Price", value: "price" },
        { text: "Action", value: "actions", sortable: false },
      ],
      desserts: [
        {
          image: 'https://cdn.vuetifyjs.com/images/cards/cooking.png',
          product_name: "Clothing Item",
          category: 'Lehenga',
          price: "10000",
          action: "open",
        },
        {
          image: 'https://cdn.vuetifyjs.com/images/cards/cooking.png',
          product_name: "Clothing Item",
          category: 'Lehenga',
          price: "10000",
          action: "open",
        },
       
      ],
    };
  },
  methods: {
    getColor(payment) {
      if (payment.toLowerCase() == "paid") return "green";
      else return "red";
    },
  },
};
</script>
<template>
  <div>    
    <preloader v-if="loading"  />
    <v-snackbar
      v-model="showSnackbar"
      :timeout="1500"
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <!-- <uploader /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      showSnackbar: false,
      openDialog: false
    }
  },
  computed: {
    // ...mapGetters(["loading", "snackbar", "uploading"]),
    ...mapGetters(["loading", "snackbar"]),
    snackbarMessage() {
      return this.snackbar? this.snackbar.message : ""
    }
  },
  watch: {
    snackbar: {
      handler(val) { 
        if(this.snackbar) { this.showSnackbar = true }
      },
      deep: true
    },
    showSnackbar(val) {
      if(val == false) { this.$store.commit("SET_SNACKBAR", null) }
    },    
  }
}
</script>
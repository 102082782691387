<template>
  <div class="add-product">
    <v-container>
      <v-row>
        <v-col>
          <h1 class="headline text-center mt-3">Add new product</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9">
          <div>
            <v-form ref="form">
              <v-text-field
                v-model="product.name"
                label="Name"
                required
                outlined
                clearable
                dense
              ></v-text-field>

              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="product.dsgCode"
                    label="DSG CODE"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.price"
                    label="Price"
                    prefix="₹"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.color"
                    label="Color"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-select
                :items="product.category"
                label="Category"
                required
                outlined
                clearable
                dense
              ></v-select>

              <v-textarea
                v-model="product.description"
                outlined
                clearable
                dense
                label="Product Description"
              >
              </v-textarea>
              <v-text-field
                v-model="product.details[0].value"
                label="Fabric"
                required
                outlined
                clearable
                dense
              ></v-text-field>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="product.details[1].value"
                    label="Delivery Time"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="product.details[2].value"
                    label="Wash Care"
                    required
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="product.details[3].value"
                    :items="availability"
                    label="Availability"
                    required
                    outlined
                    clearable
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                  <div class="col-12">
              <h4 class="mb-2">Add Product Images</h4>  
                  </div>
                  <v-col md="2">
                      <v-img class="mb-4" position="top" :src="product.images[0]"></v-img>
                        <v-file-input
                            @change="uploadImg"
                            label="File input"
                            accept="image/*"
                            chips
                            outlined
                            dense
                        ></v-file-input>
                  </v-col>
                  <v-col md="2">
                      <v-img class="mb-4" position="top" :src="product.images[1]"></v-img>
                        <v-file-input
                            @change="uploadImg"
                            label="File input"
                            accept="image/*"
                            outlined
                            dense
                        ></v-file-input>
                  </v-col>
                  <v-col md="2">
                      <v-img class="mb-4" position="top" :src="product.images[2]"></v-img>
                    <v-file-input
                        @change="uploadImg"
                        label="File input"
                        accept="image/*"
                        outlined
                        dense
                    ></v-file-input>
                  </v-col>
              </v-row>


            </v-form>
          </div>
        </v-col>
        <v-col md="3">
          <p class="body-2">First image is the product display image</p>
          <v-card flat class="grey lighten-3" height="300px">
            <v-img
              height="100%"
              src=""
            ></v-img>
          </v-card>
        </v-col>
        <v-col class="text-center" cols="12">
          <v-btn @click="handleFileUpload()">Upload Images</v-btn>
          <v-btn color="success" class="mr-4" @click="onAdd()">
            <v-icon left>mdi-plus</v-icon>
            UPDATE PRODUCT
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { addProducts, getProduct  } from "./../api/product";
import axios from "axios";

export default {
  data: () => ({
    product: {
    },
    products: [],
    row: "radio-1",
    // name: "",
    // tags: [],
    // category: [],
    // description: "",
    // images: [],
    // price: [],
    // size: [],
    // isInStock: true,
    // defaultQty: 1,
    // collection: [],
    // details: [],
    productData: {
      availability: "Made to order",
    },
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    availability: ["Made to order", "Ready to ship"],
    images:[],
    currentImage: null,
  }),
  methods: {
    
    uploadImg(event){
      let img = event
      this.images.push(img)
      console.log(this.images.length, this.images)
    },

   

  async handleFileUpload() {

     for(let image of this.images){
      var imgUrl = await this.uploadFile(image)
      this.product.images.push(imgUrl)
       
       console.log(imgUrl)
     }
    },
    uploadFile(image) {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("folder", "demo");
      return axios
        .post("http://localhost:9000/upload", formData)
        .then((res) => res.data.url)
        .catch((err) => console.log(err));
    }, 


    async onAdd() {
      // addProducts(this.product);
      const product = this.runProductAction()
      await addProducts(product)
      console.log(product)
    },
      runProductAction(){
         let _product = {
            ...this.product,
            category_slugs: this.product.category.map(item => item.split(' ').join('-').toLowerCase().toLowerCase()),
            collection_slugs: this.product.collection.map(item => item.split(' ').join('-').toLowerCase().toLowerCase()),
            slug: this.product.name.split(' ').join('-').toLowerCase(),
            price: Array.from({ length: 7 }, (_, i) => this.product.price),
            isLive: true
          }
          _product['startingPrice'] = _product.price[1]
          return _product
      },
    setProductTemplate() {
        this.product = {
          name: '',        
          collection: ['New Arrivals'],        
          category: ['Lehenga Sets'],
          sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],        
          defaultSize: "S",
          defaultQty: 1,   
          images: [],     
          description: "",
          dsgCode:'',
          isInStock: true,
          price: null,
          color:'',
          customizationAvailable: true,
          details: [
            {
              title: "Fabric",
              value: "",
            },
            {
              title: "Shipping Time",
              value: "2-3 Weeks",
            },
            {
              title: "Wash Care",
              value: "Dry Clean, Steam Iron"
            },
            {
              title: "Availablity",
              value: "Made to order"
            }
          ],
          tags: []          
        }
      }
  },

  async created(){
    this.setProductTemplate()
    let productId = this.$route.params.id    
    let productData = await getProduct(productId)    
    this.product = { ...productData, price: productData.price[0] }
    this.$forceUpdate()
  }
};
</script>

<template>
  <div class="carousel">
    <v-container>
      <v-row>
        <v-col
          md="4"
          v-for="(collection, index) in collections"
          :key="`collection-${index}`"
        >
          <v-card
            :elevation="currentCollectionIndex == index ? 5 : null"
            :outlined="currentCollectionIndex !== index"
          >
            <v-img
              height="250px"
              :src="collection.url"
              class="d-flex align-end justify-end fill-height grey lighten-3"
            >
              <div class="text-right">
                <file-upload
                  v-if="currentCollectionIndex == index"
                  @fileSelected="handleFileSelect($event, index)"
                >
                  <template v-slot:default="{ selectFile }">
                    <v-btn
                      fab
                      color="primary"
                      small
                      :loading="uploading.includes(index)"
                      class="mb-2 mr-2"
                      @click="selectFile"
                    >
                      <v-icon>mdi-image-multiple</v-icon>
                    </v-btn>
                  </template>
                </file-upload>
              </div>
            </v-img>
            <v-form
              :readonly="currentCollectionIndex !== index"
              ref="collectionForm"
              @submit.prevent="addCollection(collection.id)"
            >
              <v-card-text>
                <v-text-field label="Title" v-model="collection.name" />
                <v-text-field label="Subtitle" v-model="collection.text" />
                <v-text-field label="Text" v-model="collection.subtitle" />
              </v-card-text>
              <v-card-actions
                class="justify-end"
                v-if="currentCollectionIndex == index"
              >
                <v-btn text @click="cancelCollectionChanges(index)">
                  CANCEL
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="saveInProgress"
                  depressed
                  >SAVE</v-btn
                >
              </v-card-actions>
              <v-card-actions v-else>
                <v-btn
                  depressed
                  block
                  color="primary"
                  @click="selectCurrentCollection(index)"
                  v-if="currentCollectionIndex !== index"
                  ><v-icon left>mdi-pencil</v-icon>
                  EDIT
                </v-btn>
                <!-- <v-row align="center" no-gutters>
                  <v-col cols="6">

                  </v-col>
                  <v-col cols="6" class="pl-5">
                    <v-switch
                      class="ma-0 ml-4"
                      hide-details
                      label="Active"
                    ></v-switch>
                  </v-col>
                </v-row> -->
              </v-card-actions>
            </v-form>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-switch
                class="ma-0 mb-3 mr-3"
                :input-value="collection.active"
                @change="updateActiveState($event, collection.id)"
                hide-details
                label="Active"
              ></v-switch>
            </div>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card elevation="5">
            <v-img
              height="250px"
              :src="collectionLocal.url"
              class="grey lighten-3"
            >
              <v-row class="fill-height" justify="space-between" no-gutters>
                <v-col cols="12">
                  <v-chip
                    :disabled="currentCollectionIndex !== null"
                    class="ml-2 mt-2"
                    color="success"
                    small
                  >
                    NEW COLLECTION
                  </v-chip>
                </v-col>
                <v-col class="d-flex align-end justify-end" cols="12">
                  <file-upload @fileSelected="handleFileSelect($event)">
                    <template v-slot:default="{ selectFile }">
                      <v-btn
                        fab
                        color="primary"
                        small
                        :disabled="currentCollectionIndex !== null"
                        :loading="uploading.includes(null)"
                        class="mb-2 mr-2"
                        @click="selectFile"
                      >
                        <v-icon>mdi-image-multiple</v-icon>
                      </v-btn>
                    </template>
                  </file-upload>
                </v-col>
              </v-row>
            </v-img>
            <v-form
              :disabled="currentCollectionIndex !== null"
              ref="collectionLocalForm"
              @submit.prevent="addCollection()"
            >
              <v-card-text>
                <v-text-field label="Title" v-model="collectionLocal.name" />
                <v-text-field
                  label="Subtitle"
                  v-model="collectionLocal.subtitle"
                />
                <v-text-field label="Text" v-model="collectionLocal.text" />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  :disabled="currentCollectionIndex !== null"
                  type="submit"
                  depressed
                  block
                  :loading="saveInProgress"
                  >SAVE</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { getCollections, setCollectionsItem, updateCollection } from "./../api/collections";
import { uploadThroughApi } from "../services/Upload";

export default {
  data() {
    return {
      collectionLocal: {},
      collections: [], // component copy of collections array
      fetchedCollections: [], // original collections array
      currentCollectionIndex: null,
      uploading: [],
      saveInProgress: false,
    };
  },
  async created() {
    // this.carouselArr = this.blankCarousel();
    // this.uploaded = this.blankCarousel();
    this.collectionLocal = this.blankCollection();
    await this.refreshCollectionsData();
  },
  computed: {
    collectionsLoaded() {
      return !!this.collections.length;
    },
  },
  methods: {
    updateActiveState(val, collectionId) {
      updateCollection(collectionId, { active: val })
      .then(() => {
        this.$setSnackbar("Update successful")
      })
    },
    selectCurrentCollection(index) {
      this.currentCollectionIndex = index;
      this.collectionLocal = this.blankCollection();
      // this.collectionLocal = Object.assign({}, this.collections[index])
    },
    async handleFileSelect(val, index = null) {
      this.uploading.push(index);
      let url = await this.uploadFile(val);
      console.log(url);

      this.uploading = this.uploading.filter((item) => item !== index);
      if (index == null) {
        this.collectionLocal.url = url;
      } else {
        this.collections[index].url = url;
      }
    },
    uploadFile(image) {
      // return uploadThroughApi(image)

      const formData = new FormData();
      formData.append("file", image);
      // formData.append("folder", "assets");
      formData.append("folder", "collections");
      return axios
        .post("https://harshita-jain.df.r.appspot.com/upload/", formData)
        .then((res) => res.data.url)
        .catch((err) => console.log(err));
    },
    async addCollection(id = null) {
      // push local collection to the database
      this.saveInProgress = true;
      try {
        await setCollectionsItem(
          id,
          this.currentCollectionIndex == null
            ? this.collectionLocal
            : this.collections[this.currentCollectionIndex]
        );

        this.refreshCollectionsData();
        
        if (id == null) {
          this.resetCollectionForm();
          this.collectionLocal = this.blankCollection();
        }
        
        this.saveInProgress = false;
        this.currentCollectionIndex = null;
        this.$setSnackbar("Save successful");
      } catch (error) {
        console.log(error);
      }
    },
    cancelCollectionChanges(index) {
      this.currentCollectionIndex = null;
      this.collections[index] = Object.assign(
        {},
        this.fetchedCollections[index]
      );
      this.collectionLocal = this.blankCollection();
    },
    resetCollectionForm() {
      this.$refs.collectionLocalForm.reset();
    },
    refreshCollectionsData() {
      return new Promise((resolve, reject) => {
        getCollections()
          .then((res) => {
            this.fetchedCollections = res;
            this.collections = JSON.parse(JSON.stringify(res));
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    blankCollection() {
      return {
        name: "",
        subtitle: "",
        text: "",
        url: "",
        active: false,
      };
    },
  },
};
</script>

<template>
  <div class="carousel">
    <v-container>
      <!-- FLASH MESSAGE -->
      <v-row>
        <v-col md="12">
          <h2 class="mb-3">Flash Messages</h2>
          <div v-for="(flash, index) in AllMsgs" :key="index">
            <v-card class="pa-2 d-flex mb-1 justify-space-between">
              <div>
                <b>{{ flash.main_text }}</b>
                <br />
                <span>{{ flash.sub_text }}</span>
              </div>
              <div class="action">
                <v-btn depressed x-small class="mr-1" @click="editFlash(index)">
                  <v-icon x-small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn depressed x-small @click="deleteFlashMsg(index)">
                  <v-icon x-small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>

  
          <div class="px-3">
            <form action ref="inputForm">
              <v-text-field
                label="Primary text"
                v-model="flashMsg.main_text"
              ></v-text-field>
              <v-text-field
                label="Secondary text"
                v-model="flashMsg.sub_text"
              ></v-text-field>
              <v-btn v-if="!isEdit" color="primary" @click="addFlashMsg()"
                >Submit</v-btn
              >
              <v-btn v-else color="primary" @click="updateFlashMsg()"
                >Update</v-btn
              >
            </form>
          
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { setCarousel, getCarousel } from "./../api/carousel";

export default {
  data() {
    return {
      isEdit: false,
      AllMsgs: null,
      flashMsgs: [],
      flashMsg: {
        main_text: "",
        sub_text: "",
      },
      currentIndex: null,
    };
  },

  methods: {

    async fetchData(){
       let res = await axios.get(
      "https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/flash_message.json"
    );
    this.AllMsgs = res.data;
    },
    async addFlashMsg() {
      this.flashMsgs.push(Object.assign({}, this.flashMsg));
      await axios.put(
        `https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/flash_message/${this.AllMsgs.length}.json`,
        this.flashMsg
      );
      this.fetchData()
    },

    async updateFlashMsg() {
      await axios.put(
        `https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/flash_message/${this.currentIndex}.json`,
        this.flashMsg  
      );
      this.fetchData()
      this.isEdit = false
    },

    editFlash(index) {
      this.isEdit = true;
      this.currentIndex = index;
      this.flashMsg = Object.assign({},this.AllMsgs[index])
    },

    async deleteFlashMsg(index){
      await axios.delete(` https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/flash_message/${index}.json`)
      this.fetchData()
    }
  },
  async created() {
   this.fetchData()
  },
};
</script>
-
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Preloader from './components/global/Preloader';
import FileUpload from './components/global/FileUpload';
import { auth } from './services/Auth'
import RandomString from 'randomstring'

Vue.config.productionTip = false
Vue.component('Preloader', Preloader)
Vue.component('file-upload', FileUpload)

// ADDING SLUG TO PRODUCTS

export const slugify = (data, appendRandom = false) => {
  appendRandom = !!appendRandom ? '-' + RandomString.generate({
    charset:'numeric',
    length:5
  }) : ''
  console.log('Slug Changed')
  return data.toLowerCase().trim().split(' ').join('-') + appendRandom
}

let setSnackbar = (message) => {
  store.commit("SET_SNACKBAR", { message })
}

const typeEquals = (data, type) => {
  return typeof data == type
}

Vue.prototype.$slugify = slugify
Vue.prototype.$typeEquals = typeEquals
Vue.prototype.$setSnackbar = setSnackbar

var app;

auth().onAuthStateChanged(user => {  
  router.beforeEach((to,from,next)=>{ 

    if(to.matched.some(record=>record.meta.requiresAuth)) {
      if(!!auth().currentUser) {
        next()
      } else {      
        next('/login')
      } 
    } else if(to.matched.some(record=>record.meta.requiresGuest)) {
      if(!!auth().currentUser) {   
        next('?ref=sign-in')
      } else {
        next()
      }     
    }
    else {
      next()
    } 
  })
  // store.commit("SET_AUTH_DATA", !!d? auth().currentUser : null)
  // router.beforeEach((to,from,next)=>{     
  //   if(!!auth().currentUser) {
  //     if(to.path = '/login') {
  //       next("/")
  //     }
  //     next()
  //   }
  //   next("/login")    
  // })
  if (!app) { initApp() }
})

function initApp() {  
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
import { collectionsRef } from "../services/Database"
import { slugify } from './../main'

export function setCollectionsItem(id, data) {
  
  if(id == null) {
    id = collectionsRef.doc().id
    return collectionsRef.doc(id)
      .set({ ...data, slug: slugify(data.name), id: id, timestamp: Date.now() })    
  } else {
    return collectionsRef.doc(id)
    .update(data)
  }
}

export function updateCollection(id, data) {
  return collectionsRef.doc(id).update(data)
}

export function getCollections() {
  let res = []
  return collectionsRef.orderBy('timestamp', 'asc').get()
    .then(snaps => {
      snaps.forEach(snap => res.push(snap.data()))
      return res
    })
}
<template>
  <div class="home-banners">
    <v-container fluid>
      <h2>Desktop Banners</h2>
      <v-row>
        <v-col md="12">
          <div class="banner-preview">
            <v-img cover :src="previewImg.desktop" height="400"></v-img>
          </div>
        </v-col>
        <v-col md="8" offset-md="2">
          <v-card class="mt-5 pa-2">
            <v-file-input
              @change="uploadBanner($event, 'desktop')"
              label="File input"
              accept="image/*"
              outlined
              dense
              hide-details
              :disabled="isUploading.desktop"
              @click:clear="removeBanner()"
            ></v-file-input>
            <v-btn
              :loading="isUploading.desktop"
              class="mt-4"
              @click="submitBanner('desktop')"
              block
              color="primary"
            >
              <v-icon right dark class="mr-1"> mdi-cloud-upload </v-icon>Upload
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <hr class="my-5" />
      <h2>Mobile Banners</h2>
      <v-row>
        <v-col md="6">
          <v-img :src="previewImg.mobile"></v-img>
          <v-card class="pa-2">
            <v-file-input
              outlined
              dense
              hide-details
              :disabled="isUploading.mobile"
              @change="uploadBanner($event, 'mobile')"
            >
            </v-file-input>
            <v-btn
              :loading="isUploading.mobile"
              class="mt-4"
              @click="submitBanner('mobile')"
              block
              color="primary"
            >
              <v-icon right dark class="mr-1"> mdi-cloud-upload </v-icon>Upload
            </v-btn>
          </v-card>
        </v-col>
        <v-col md="6"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentImg: {
        desktop: null,
        mobile: null,
      },
      bannerImg: "",
      previewImg: "",
      isUploading: {
        desktop: false,
        mobile: false,
      },
    };
  },

  methods: {
    uploadBanner(e, screen) {
      this.currentImg[screen] = e;
      //   this.previewImg = { desktop: URL.createObjectURL(e) };
      this.previewImg[screen] = URL.createObjectURL(e);
      // this.$forceUpdate()
    },

    removeBanner() {},

    async submitBanner(screen) {
      const isMobile = screen == "mobile";
      this.isUploading[screen] = true;
      const url = await this.uploadFile(this.currentImg[screen]);
      const api = `https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/home_banner.json`;
      //   if (screen === "desktop") {
      await axios
        .put(api, {
          desktop: isMobile ? this.bannerImg.desktop : url,
          mobile: isMobile ? url : this.bannerImg.mobile,
        })
        .then(() => {
          console.log("uploaded Successfully")
          this.$setSnackbar("Upload successful")          
        })
        .catch((e) => { 
          this.$setSnackbar("Upload failed")                    
          console.log("Error setting home-banner", e)
        })
        .finally(() => (this.isUploading[screen] = false));
      //   } else {
      // console.log('Mobile version')
      //   }
    },

    uploadFile(image) {
      // console.log(image);
      // return uploadThroughApi(image)

      const formData = new FormData();
      formData.append("file", image);
      formData.append("folder", "banner_images");
      formData.append("size", 1800);
      //   return 'url of image';
      return axios
        .post("https://harshita-jain.df.r.appspot.com/upload/", formData)
        .then((res) => res.data.url)
        .catch((err) => console.log(err));
    },
  },
  async mounted() {
    let bannerImges = await axios
      .get(
        "https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/home_banner.json"
      )
      .then((e) => {
        return e.data;
      });
    this.previewImg = bannerImges;
    this.bannerImg = Object.assign({}, bannerImges);
  },
};
</script>

<style scoped>
.banner-preview {
  height: 400px;
  background: #ddd;
}
</style>
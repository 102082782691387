<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Active</v-tab>
      <v-tab>Compelted</v-tab>
      <v-tab>Cancelled</v-tab>
      <v-tab>All</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="i in 4" :key="i">
        <OrdersTable :orders="allOrders"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>


<script>
import OrdersTable from './OrdersTable.vue'
import { getOrders } from './../api/order'
export default {
  data: () =>({
    tab:null,
    allOrders: null
  }),
  components: {
    OrdersTable
  },
  mounted(){
    const order = getOrders().then((res) => {
      console.log(res)
      console.log( {
          id: 124454,
          product: "Frogen Lahenga, Lahrnfa, banana",
          qty: 1,
          date: "27-Oct-2021",
          email: "tomcruise@gmail.com",
          amount: 2000,
          action: "open",
          payment: "Paid",
        })
      this.allOrders = res
    })
  }
}
</script>
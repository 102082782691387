<template>
  <v-app>
    <v-app-bar v-if="isLoggedIn" app color="primary" hide-on-scroll dark>
      <div class="d-flex align-center">
        <v-icon @click="toggleDrawer()">mdi-menu</v-icon>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.harshitajain.co.in"
        target="_blank"
        text
      >
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="isLoggedIn" v-model="drawer" app mini-variant.sync="true">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Harshita Jain
          </v-list-item-title>
          <v-list-item-subtitle>
            Dashboard
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <!-- <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <v-list dense nav>
        <v-list-item to="/orders">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view @refreshView="handleRefreshView" :key="componentKey" />
      <AppActions />
    </v-main>
  </v-app>
</template>

<script>
import AppActions from "@/components/AppActions";
import { signOut, auth } from './services/Auth'
import { fetchProductsList } from './api/product.js'
export default {
  name: "App",
  data: () => ({
    drawer: true,
    componentKey: 1,
    // items: [
    //       { title: 'Dashboard', icon: 'mdi-view-dashboard', link:'/' },
    //       { title: 'Products', icon: 'mdi-cube', link:'/products' },
    //       { title: 'About', icon: 'mdi-help-box', link:'/about'},
    //     ],
    items: [
      {
        action: "mdi-cube",
        active: false,
        items: [
          { title: "List", link: "/products" },
          { title: "Add", link: "/add-product" },
        ],
        title: "Products",
      },
      // {
      //   action: "mdi-cart",
      //   active: false,
      //   items: [
      //     { title: "New Orders", link: "/new-orders" },
      //     { title: "Shipped ", link: "/shipped-orders" },
      //   ],
      //   title: "Orders",
      // },
      {
        action: "mdi-wrench",
        active: false,
        items: [
          { title: "Collections", link: "/collections" },
          // { title: "Featured", link: "/featured" },
          { title: "Flash Message", link: "/flash-message" },
          { title: "Home Banners", link: "/home-banners" },
          { title: "Home Carousel", link: "/carousel" },
        ],
        title: "UI Elements",
      },
    ],
  }),
  computed: {
    isLoggedIn() {
      return !!auth().currentUser
    }
  },
  methods: {
    signOut() {
      signOut()
      .then(() => {
        window.location = '/login'
      })
    },
    toggleDrawer() {
      console.log("click");
      this.drawer = !this.drawer;
    },
    handleRefreshView() {
      this.componentKey = this.componentKey * (-1)
    }
  },
  components: {
    AppActions,
  },
  mounted(){
    fetchProductsList()
  }
};
</script>

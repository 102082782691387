import { productsRef, searchRef, FieldValue } from "../services/Database"
import store from './../store'

// export function getProducts() {
//     let allProducts = [];
//     return productsRef.get()
//         .then(snaps => {
//             snaps.forEach(snap => allProducts.push(snap.data()))
//             return allProducts;
//         })
// }


export function addSearchItems(data){
    console.log(data)
    return searchRef.doc("store").update({
        items : FieldValue.arrayUnion(...data)
    })
}

export function fetchProductsList() {
    let products = []

    return new Promise((resolve, reject) => {
        productsRef
            // .limit(40)
            .onSnapshot(snaps => {
                products = []
                snaps.forEach(snap => {
                    products.push(snap.data())
                })
                store.commit("SET_PRODUCTS_LIST", products.map(item => ({ ...item, createdAt: !item.createdAt ? !!item.timestamp ? item.timestamp : 0 : item.createdAt })).sort((a, b) => b.createdAt - a.createdAt))
                store.commit("SET_PRODUCTS_LOADED", true)
                resolve(products)
            })
    })


}

// export const listProducts = (condition) => {
//     return new Promise((resolve, reject) => {
//         // let ref = productsRef.where('isLive','==', true)
//         let ref = productsRef
//         if (!!condition) {
//             ref = ref.where(`${condition.field}`, "array-contains", `${condition.value}`)
//         }
//         let docs = []
//         ref
//             .limit(50)
//             .get()
//             .then(snaps => {
//                 let data;

//                 snaps.forEach(async snap => {
//                     data = snap.data()
//                     docs.push(data)
//                 })

//                 return resolve(docs.map(item => ({ ...item, createdAt: !item.createdAt ? !!item.timestamp ? item.timestamp : 0 : item.createdAt })).sort((a, b) => b.createdAt - a.createdAt))
//             })
//             .catch(e => reject(e))
//     })
// }

export function getProduct(id) {
    return productsRef.doc(id).get()
        .then(snap => snap.data())
}

export function removeProduct(id) {
    return productsRef.doc(id).delete()
}

export function addProduct(data) {
    let id = productsRef.doc().id
    return productsRef.doc(id)
        .set({ ...data, id: id, createdAt: Date.now() })
}

export function updateProduct(data, id) {
    return productsRef.doc(id)
        .update(data)
}

// export function addProducts(data){
//     console.table(data)
//     if(data.id == null) {
//         let id = productsRef.doc().id
//         console.log(id)
//         return productsRef.doc(id)
//           .set({ ...data, id: id, timestamp: Date.now() })
//       } else {
//         return productsRef.doc(id)
//         .update(data)
//       }
// }
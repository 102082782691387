<template>
  <div class="featured">
    <v-container fluid>
      <v-row>
        <v-col md="12"> </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <template>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mb-5"
            ></v-text-field>    
            <v-switch
            label="Show only Featured"
            :value="false"
            ></v-switch>     
            <v-data-table
              dense
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
              :loading="false"
              loading-text="Loading... Please wait"
              :search="search"
            >
              <template v-slot:item.image="{ item }">
                  <div class="pa-1">

                <v-img   :src="item.image" height="100px" width="70px"></v-img>
                  </div>
              </template>
              <template v-slot:item.payment="{ item }">
                <v-chip :color="getColor(item.payment)" dark small>
                  {{ item.payment }}
                </v-chip>
              </template>
              <template v-slot:item.isFeatured="{ item }">
                <v-switch
                v-model="item.isFeatured"
                ></v-switch>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
        },
        { text: "Product Name", value: "product_name" },
        { text: "Category", value: "category" },
        { text: "Price", value: "price" },
        { text: "Featured", value: "isFeatured", sortable: false },
      ],
      desserts: [
        {
          image: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
          product_name: "Azub Lahenga",
          category: "Lehenga",
          price: "10000",
          action: "open",
          isFeatured: true
        },
        {
          image: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
          product_name: "Clothing Item",
          category: "Lehenga",
          price: "10000",
          action: "open",
          isFeatured: false
        },
      ],
    };
  },
  methods: {
    getColor(payment) {
      if (payment.toLowerCase() == "paid") return "green";
      else return "red";
    },
  },
};
</script>
